import * as React from 'react'
import { PhysnetServices } from '../components/physnetServices'
import { OurServices } from '../components/ourServices'
import { SecondaryServices } from '../components/secondaryServices'
import { Layout } from '../components/layout'
import { PageHeader } from '../components/pageHeader'

export default function IndexPage () {
  return (
    <Layout>
      <PageHeader>Las NQ</PageHeader>
      <div className="container flex flex-col px-6 py-4 mx-auto   lg:flex-row items-baseline">
        <OurServices />
        <PhysnetServices />
      </div>
      <div className={'container mx-auto'}>
        <SecondaryServices />
      </div>
    </Layout>
  )
}
