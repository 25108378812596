import * as React from 'react'
import { BiArchive, BiServer, BsTruck } from 'react-icons/all'
import { PrimaryButton } from './snippets/buttons'
import PropTypes from 'prop-types'

export function AdditionalLink (props) {
  return (
    <>
      <div className="flex rounded-lg h-full bg-gray-100 dark:bg-gray-800 p-8 flex-col">
        <div className="flex items-center mb-3">
          <div className="text-black dark:text-white text-2xl pr-1 ">
            {props.icon}
          </div>
          <h2 className="text-gray-900 dark:text-white text-lg title-font font-medium">
            {props.title}
          </h2>
        </div>
        <div className="flex-grow">
          <p className="leading-relaxed text-base dark:text-gray-400">
            {props.description}
          </p>

          <a href={props.href} className="mt-3 inline-flex items-center ">
            <PrimaryButton>Go to {props.title}</PrimaryButton>
          </a>
        </div>
      </div>
    </>
  )
}
AdditionalLink.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node
}

export function SecondaryServices () {
  return (
    <>
      <section className="text-gray-600 body-font mt-5 ">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h2 className="text-pink-500 tracking-wide font-medium title-font mb-10">
              Additional Links
            </h2>
          </div>
          <div className="flex flex-wrap -m-4 md:items-center mx-auto">
            <div className="p-4 md:w-1/4 mx-auto">
              <AdditionalLink
                href={'https://archive.lasnq.physnet.uni-hamburg.de/'}
                icon={<BiArchive />}
                title={'Archive'}
                description={
                  'List of old services. May or may not be online or secure.'
                }
              />
            </div>
            <div className="p-4 md:w-1/4 w-full mx-auto">
              <AdditionalLink
                href={
                  'https://www.physik.uni-hamburg.de/en/ilp/ag-schnabel.html'
                }
                icon={<BiServer />}
                title={'UHH Website'}
                description={'Our website on uni-hamburg.de'}
              />
            </div>

            <div className="p-4 md:w-1/4 w-full mx-auto">
              <AdditionalLink
                href={'/suppliers.html'}
                icon={<BsTruck />}
                title={'Suppliers'}
                description={'A list of suppliers we have used in the past'}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
