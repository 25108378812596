import { InventoryIcon, WikiIcon } from '../images/icons'
import {
  BiCube,
  FaPython, FaWind,
  SiConfluence,
  SiInfluxdb,
  SiMattermost
} from 'react-icons/all'
import * as React from 'react'
import { ServiceLink } from './serviceLink'

export function OurServices () {
  return (
    <>
      <div className="flex flex-col items-center w-full lg:flex-col lg:w-1/2">
        <h2 className=" text-pink-500 tracking-wide font-medium title-font">
          Group internal Services
        </h2>

        <div className="flex flex-col container max-w-md mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
          <ul className="flex flex-col divide-y dark:divide-gray-700 w-full">
            <ServiceLink
              title={'Confluence'}
              description={'Knowledge base and digital lab book.'}
              href={'https://wiki.lasnq.physnet.uni-hamburg.de/'}
              icon={<SiConfluence className="scale-150" />}
            />
            <ServiceLink
              title={'Outline'}
              description={'Knowledge base and digital lab book.'}
              href={'https://outline.lasnq.physnet.uni-hamburg.de/'}
              icon={<WikiIcon />}
            />
            <ServiceLink
              title={'Baserow'}
              description={
                'A fun-to-use database. Use for project management and inventory.'
              }
              href={'https://database1.lasnq.physnet.uni-hamburg.de/'}
              icon={<InventoryIcon />}
            />
            <ServiceLink
              title={'Chat (Mattermost)'}
              description={'Internal communication. Emails are old school.'}
              href="https://las-nq-serv.physnet.uni-hamburg.de/"
              icon={<SiMattermost className="scale-150" />}
            />
            <ServiceLink
              title={'Data (InfluxDB)'}
              description={'Database for timeseries data'}
              href={'https://data.lasnq.physnet.uni-hamburg.de/'}
              icon={<SiInfluxdb className="scale-150" />}
            />

            {/*<ServiceLink*/}
            {/*  title={'Python packages'}*/}
            {/*  description={*/}
            {/*    'A PyPi server hosting our python packages: nqlab, nqstyles, drc_calculator and raspi_temp'*/}
            {/*  }*/}
            {/*  href={'https://packages.lasnq.physnet.uni-hamburg.de/'} */}
            {/*  icon={<FaPython className="scale-150" />}*/}
            {/*/>*/}

            <ServiceLink
              title={'Vacuum Pi'}
              description={
                'Vacuum surveillance and control systems'
              }
              href={'https://vacuum.lasnq.physnet.uni-hamburg.de/'}
              icon={<FaWind className="scale-150" />}
            />

            <ServiceLink
              title={'3D printing'}
              href="https://printing.lasnq.physnet.uni-hamburg.de/"
              description={'The web interface of our 3D printer.'}
              icon={<BiCube className="scale-150" />}
            />
          </ul>
        </div>
      </div>
    </>
  )
}
