import {
  AiFillGitlab,
  BiHelpCircle,
  BsTools, MdOutlineMedicalServices,
  SiJupyter,
  SiMatrix,
  SiNextcloud, SiOverleaf
} from 'react-icons/all'
import * as React from 'react'
import { ServiceLink } from './serviceLink'

export function PhysnetServices () {
  return (
    <>
        <div className="flex flex-col items-center w-full lg:flex-col lg:w-1/2 ">
        <h2 className=" text-pink-500 tracking-wide font-medium title-font mt-6">
          PHYSnet Services
        </h2>
        <div className="flex flex-col container max-w-md mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
          <ul className="flex flex-col divide-y dark:divide-gray-700 w-full">
            <ServiceLink
              title={'Gitlab'}
              description={'Everything code-related'}
              href={'https://git.physnet.uni-hamburg.de/'}
              icon={<AiFillGitlab className="scale-150" />}
            />

            <ServiceLink
              title={'Jupyter Hub'}
              description={'Run your iPython notebooks in our powerful cloud'}
              href={'https://jhub.physnet.uni-hamburg.de/'}
              icon={<SiJupyter className="scale-150" />}
            />
             <ServiceLink
              title={'Overleaf'}
              description={'Collaborative Latex writing. Ask Physnet admins for account.'}
              href={'https://overleaf.physnet.uni-hamburg.de/'}
              icon={<SiOverleaf className="scale-150" />}
            />
            <ServiceLink
              title={'Nextcloud (Wolke)'}
              description={'Shared calendar and private file storage'}
              href="https://wolke.physnet.uni-hamburg.de/"
              icon={<SiNextcloud className="scale-150" />}
            />

            <ServiceLink
              title={'PHYSnet Chat'}
              description={'Chat with other PHYSnet members outside our group'}
              href={'https://riot.physnet.uni-hamburg.de'}
              icon={<SiMatrix className="scale-150" />}
            />
            <ServiceLink
              title={'PHYSnet Knowledge Base'}
              description={'Some documentation regarding PHYSnet usage'}
              href={'https://support.physnet.uni-hamburg.de/help/de-de'}
              icon={<BiHelpCircle className="scale-150" />}
            />

            <ServiceLink
              title={'Workshop'}
              description={'The Auftragsverwaltungssystem of the workshop'}
              href={'https://avs.physnet.uni-hamburg.de/'}
              icon={<BsTools className="scale-150" />}
            />
            <ServiceLink
              title={'IT Abteilung UHH'}
              description={'Druckkarten, Softwarelizenzen, Mailinglisten, Support'}
              href={'https://rrz-serviceportal.uni-hamburg.de/'}
              icon={<MdOutlineMedicalServices className="scale-150" />}
            />
          </ul>
        </div>
      </div>
    </>
  )
}
